<template>
  <div class="home">
    <ul>
      <li v-for="link in links" :key="link">
        <a :href="link.url">{{link.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data(){
    return {
      links: [
        {
          name: 'alix',
          url: '/project/alix'
        },
        {
          name: 'aurel',
          url: '/project/aurel'
        },
        {
          name: 'aurelian',
          url: '/project/aurelian'
        },
        {
          name: 'aruna',
          url: '/project/aruna'
        }
      ]
    }
  }
}
</script>
